<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">
        {{
          getProcedureTypes[$route.params.procedureType]
            ? getProcedureTypes[$route.params.procedureType].name
            : ""
        }}
        /
        {{
          centralization
            ? centralization.identifier + " - " + centralization.name
            : ""
        }}
      </div>
    </div>
    <template>
      <template v-if="PERMISIONS.list">
        <table-parent
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          :apiModuleName="'ANNOUNCEMENT'"
          :tableMinimizeHead="true"
          :tableSpread="true"
          @Btn7="openActions"
          @Btn14="viewPdf"
          @Btn2="editItem"
          @Btn6="openAddress"
          @Btn3="deleteItem"
          @Btn42="requestApprove"
          @totalRecords="(x) => (totalRecords = x)"
        >
        </table-parent>
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "../mixins/announcementPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import { ANNOUNCEMENT, GET_PAAP_CENTRALIZATION_PAGINATED } from "../api.js";
import DialogModal from "@/components/DialogModal";
import TableParent from "@/components/TableParent";
import { mapMutations, mapGetters } from "vuex";
import VerbalProcessProviders from "@/components/VerbalProcessProviders";
import Announcement from "@/components/Announcement";
import AssignmentActions from "@/components/AssignmentActions";

export default {
  name: "Achizitii",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },

  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent,
    VerbalProcessProviders,
  },

  data() {
    return {
      centralization: null,
      tableHead: [
        {
          title: "Numarul anunțului",
          queryKey: "announcementNumber",
          bottomHead: {
            quikFiltre: true,
          },
        },

        {
          title: "Data anunțului",
          queryKey: "announcementDate",
          bottomHead: {
            quikFiltre: "date",
          },
        },

        {
          title: "Data limită pentru transmiterea ofertelor",
          queryKey: "expireDate",
          bottomHead: {
            quikFiltre: "date",
          },
        },

        {
          title: "Limba de redactare a ofertei",
          queryKey: "language",
          bottomHead: {
            quikFiltre: true,
          },
        },

        {
          title: "Sursa de finanțare a contractului",
          queryKey: "financingSource",
          bottomHead: {
            quikFiltre: true,
          },
        },

        {
          title: "Perioada de valabilitate a ofertelor (zile)",
          queryKey: "valability",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Propunerea tehnică",
          queryKey: "technicalProposal",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Propunerea financiară",
          queryKey: "financialProposal",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Criteriul de atribuire",
          queryKey: "attributionCriteria",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: "status",
          bottomHead: {
            quikFiltre: "acquisitionStatus",
          },
        },
      ],
      tableModule: (x, y) =>
        ANNOUNCEMENT.get(
          x,
          y,
          `eq=paapCentralizationId,${this.$route.params.centralization}`
        ),
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    ...mapMutations(["setReferenceIdForPreview"]),

    reload() {
      this.reloadTable++;
    },
    prepareData(row) {
      const preparation = [
        row.announcementNumber || "",
        this.toDate(row.announcementDate) || "",
        this.toDate(row.expireDate) || "",
        row.language || "",
        row.financingSource || "",
        row.valability || "",
        row.technicalProposal || "",
        row.financialProposal || "",
        row.attributionCriteria || "",
        {
          draft: {
            border: "left",
            icon: "far fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2rem;",
            tooltip: "Draft",
          },
          generated: {
            border: "left",
            icon: "fas fa-pencil",
            css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
            tooltip: "Generat",
          },
          waiting_approve: {
            border: "left",
            icon: "fas fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2rem;",
            tooltip: "Așteptare aprobare",
          },
          rejected: {
            border: "left",
            icon: "fas fa-times-circle",
            css: "padding: 0.5rem;color: #ec4a4a;font-size: 2rem;",
            tooltip: "Respins",
          },
          approved: {
            border: "left",
            icon: "fas fa-clipboard-check",
            css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
            tooltip: "Aprobat",
          },
        }[row.status] || "-",
      ];

      const x = this.PERMISIONS;

      const prepareBtn = [];
      if (row.status == "approved" && !row.acquisition) {
        prepareBtn.push([
          7,
          "Crează achiziție",
          ["fas fa-gears", "font-size:2.2rem; color:green;"],
        ]);
      } else if (!row.acquisition) {
        prepareBtn.push([
          -1,
          "Indisponibil crearea achiziție până la aprobarea anunțului",
          ["fas fa-gears", "font-size:2.2rem; color:green; opacity: 0.5; cursor: initial"],
        ]);
      }

      prepareBtn.push(14);

      if (row.status != "approved" && row.status != "waiting_approve") {
        prepareBtn.push(2);
      }

      if (row.status == "draft" || row.status == "rejected") {
        prepareBtn.push([
          42,
          "Trimite spre semnare",
          ["fas fa-signal-stream", "font-size:2.2rem;color:#39f;"],
        ]);
      }

      if ((x.approve || x.reject) && row.status == "waiting_approve") {
        prepareBtn.push(8);
        prepareBtn.push(9);
      }

      if (row.status == "waiting_approve") {
        prepareBtn.push([
          6,
          "Deschide lucrarea",
          ["fas fa-book", "font-size:2.2rem;color:#39f;"],
        ]);
      }
      if (row.status != "approved" && row.status != "waiting_approve") {
        prepareBtn.push(3);
      }

      if (prepareBtn.length) {
        preparation.push([prepareBtn]);
      }

      return Object.assign(preparation, {
        _Data: row,
      });
    },

    viewPdf(data) {
      this.setReferenceIdForPreview({
        data: {
          id: data.id,
        },
        origin: "ANNOUNCEMENT_PDF",
      });
    },

    requestApprove(data) {
      const error = (msg) => {
        this.$toastr.e(msg || "Eroare semanre.");
        this.reload();
        this.setLoad();
      };

      this.setSafeLoad(12000);
      ANNOUNCEMENT.requestApprove(data.id)
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            window.open(res.data.result.url, "_blank");
            this.reload();
            this.setLoad();
          } else {
            error(res.response);
          }
        })
        .catch(error);
    },

    openActions(item) {
      this.$modal.show(
        DialogModal,
        {
          target: "ActiuniAchizitie",
          title: "Acțiuni achiziții",
          inputs: [
            {
              id: "assignmentActions",
              type: "component",
              component: AssignmentActions,
              errReason: "",
              componentProps: {
                announcement: item,
              },
            },
          ],
          closeBtnText: "Anulează",
          button: {
            disabled: true,
          },
          customClass: "fullWidth",
        },
        {
          name: "ActiuniAchizitie",
          adaptive: true,
          width: "650px",
          height: "570px",
        }
      );
    },

    editItem(item) {
      this.$modal.show(
        DialogModal,
        {
          target: "EDIT_Announcement",
          title: "Editare anunț",
          inputs: [
            {
              id: "announcement",
              type: "component",
              component: Announcement,
              componentProps: { initial: { ...item } },
            },
          ],

          acceptFinish(data) {
            const announcement = data.announcement;

            delete announcement.acquisition;

            for (const key in announcement) {
              if (!announcement[key]) {
                this.$toastr.w("Nu au fost completate toate secțiunile.");
                return false;
              }
            }
            return true;
          },

          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Modifică",
            handler: (data) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Modificarea procesului verbal a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              ANNOUNCEMENT.edit(item.id, {
                ...data.announcement,
              })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Procesul verbal a fost modificat.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },
          customClass: "custom-size",
        },
        {
          name: "EDIT_Announcement",
          adaptive: true,
          width: "1000px",
          height: "1000px",
        }
      );
    },

    deleteItem(item) {
      if (!Number.isInteger(item?.id)) {
        return;
      }

      this.$modal.show(
        DialogModal,
        {
          target: "DeleteAnnouncement",
          title: "Șterge procesul verbal",
          inputs: [],
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(msg || "Procesul verbal nu a fost șters.");
                this.reload();
                this.setLoad();
              };

              this.setLoad(true);
              ANNOUNCEMENT.delete(item.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Procesul verbal a fost șters.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "DeleteAnnouncement",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
    openAddress(item) {
      window.open(item.conectxAddressUrl, "_blank");
    },
  },

  computed: {
    ...mapGetters(["getProcedureTypes"]),
  },
  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    const canViewButtonsColumn = ["approve", "edit", "delete"];

    this.updateRoleStatus();
    if (this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
    }

    GET_PAAP_CENTRALIZATION_PAGINATED(
      1,
      1,
      `eq=id,${this.$route.params.centralization}`
    ).then((res) => {
      this.centralization = res.data.result[0];
      const path = this.$route.path;
      const val = path.slice(0, path.lastIndexOf("/"));

      this.$root.$emit("select-item", val.slice(0, val.lastIndexOf("/")));
    });
  },
};
</script>
<style>
.custom-size {
  max-width: 1200px !important;
  max-height: 100% !important;
}
</style>
